@font-face {
  font-family: 'D-DIN';
  src: url('../font/D-DIN-Bold.eot');
  src: url('../font/D-DIN-Bold.eot?#iefix') format('embedded-opentype'),
      url('../font/D-DIN-Bold.woff2') format('woff2'),
      url('../font/D-DIN-Bold.woff') format('woff'),
      url('../font/D-DIN-Bold.ttf') format('truetype'),
      url('../font/D-DIN-Bold.svg#D-DIN-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
